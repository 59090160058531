import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { useBoolean } from '../utils/hooks';
import Layout from '../components/layout';
import { mapPhotoSwipeImages } from '../utils/helpers';
import loadable from '@loadable/component';
import { getSrc } from 'gatsby-plugin-image';

import '../styles/project.scss';

const Content = loadable(() => import('../components/content'));
const GalleryGrid = loadable(() => import('../components/gallery-grid'));
const PhotoSwipeWrapper = loadable(() => import('../components/photoSwipe'));

const Project = ({ data }) => {
  const { project, gallery } = data;

  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [index, setIndex] = useState(0);

  const headElementRef = useRef();

  const overlay = 'linear-gradient(#0000 0%, #0000 85%, #000C 100%)';

  const items = mapPhotoSwipeImages(gallery.edges);

  const handleOpen = (index) => {
    showModal();
    setIndex(index);
  };

  const metaData = {
    title: project.frontmatter.title,
    description: project.frontmatter.description
  };

  return (
    <Layout metaData={metaData} headElementRef={headElementRef}>
      <Content type="full">
        <div className="project">
          <section
            className="project-hero"
            style={{
              backgroundImage: `${overlay}, url(${getSrc(project.frontmatter.coverImage)})`
            }}
          >
            {project.frontmatter.city && <p className="project-city">{project.frontmatter.city}</p>}
            <h1 className={`project-title${project.frontmatter.city ? ' project-title-multi' : ''}`}>
              {project.frontmatter.title}
            </h1>
          </section>
          <span className="headElementRef-placeholder" ref={headElementRef}></span>
          <Content type="wide">
            <GalleryGrid
              childRef={(ref) => (this.child = ref)}
              items={items.small}
              open={handleOpen}
              title={metaData.title}
            />
          </Content>
          <PhotoSwipeWrapper isOpen={isVisible} index={index} items={items.large} onClose={hideModal} />
        </div>
      </Content>
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query($slug: String!) {
    project: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        city
        coverImage {
          ...largeImage
        }
      }
      fields {
        slug
      }
    }
    gallery: allFile(filter: { extension: { eq: "jpg" }, dir: { regex: $slug } }, sort: { fields: name, order: ASC }) {
      edges {
        node {
          id
          extension
          dir
          ...galleryImages
        }
      }
    }
  }
`;
